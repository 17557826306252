<template>
  <main-body-section class="tension-page">
    <template #map>
      <div id="map" class="h-100 z-1"></div>
    </template>
    <base-panel title="انتخاب زمین" :rightPanel="false" class="text-center">
      <template #default>
        <vue-element-loading
          :active="farmsNameListLoadingState"
          text=""
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <farms-name-list
          :farmsNameList="farmsSource"
          :farmersNameList="farmsSource"
          :displayReturnButton="false"
          :selectFarmLoading="selectFarmLoading"
          @selectFarm="selectFarm"
        ></farms-name-list>
      </template>
    </base-panel>
    <transition name="fade">
      <base-panel
        :rightPanel="true"
        v-if="selectedFarm"
        title="مشخصات زمین"
      >
        <template #default>
          <farm-info
            :farmInfo="selectedFarm"
            :area="selectedFarmArea"
          ></farm-info>
        </template>
      </base-panel>
    </transition>
    <vue-element-loading
      :active="deleteLoading"
      text="در حال حذف بنر..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <VueModal
      v-model="modalShow"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
        <CRow class="gap-10">
          <CCol md="12">
            <small class="ml-1 inputLabel">عنوان</small>
            <CInput
              type="text"
              v-model="formModel.title"
              :class="{'is-invalid': !$v.formModel.title.required && $v.formModel.title.$error}"
            />
            <div v-if="!$v.formModel.title.required && $v.formModel.title.$error" class="invalid-feedback">
              عنوان مورد نیاز است
            </div>
          </CCol>
          <CCol md="12">
            <small class="ml-1 inputLabel">نوع ژئودیتابیس</small>
            <v-select
              dir="rtl"
              v-model="formModel.type"
              :options="geoDataBaseTypeSource"
              placeholder="انتخاب نوع ژئودیتابیس"
              :class="{'is-invalid': !$v.formModel.type.required && $v.formModel.type.$error}"
            ></v-select>
            <div v-if="!$v.formModel.type.required && $v.formModel.type.$error" class="invalid-feedback">
              نوع ژئودیتابیس مورد نیاز است
            </div>
          </CCol>
          <br/>
          <CCol md="12">
            <small class="ml-1 inputLabel">توضیحات</small>
            <CTextarea
              type="text"
              v-model="formModel.description"
              style="height: unset"
              rows="5"
              :class="{'is-invalid': !$v.formModel.description.required && $v.formModel.description.$error}"
            ></CTextarea>
            <div v-if="!$v.formModel.description.required && $v.formModel.description.$error" class="invalid-feedback">
              توضیحات مورد نیاز است
            </div>
          </CCol>
          <br/>
          <CCol md="12">
            <input
              type="file"
              accept="image/*"
              ref="image"
              style="display: none"
              @change="onGeoDataBaseImageChange"
            />
            <CButton
              color="secondary"
              size="lg"
              class="y-center-g-5"
              @click="$refs.image.click()"
            >
              <CIcon name="cil-camera" style="color: white" size="lg"/>
              <span>{{ imageText }}</span>
            </CButton>
          </CCol>
          <CCol md="12" class="mt-4">
            <CButton @click="saveGeoDataBasePoint()" class="y-center-g-5 justify-content-center" color="primary" block>
              <CIcon name="cil-check"/>
              <span>ثبت</span>
            </CButton>
          </CCol>
          <!--        <div class="imageSelectedPreview">-->
          <!--          <img :src="imagePreview" style="max-width: 100%; max-height: 100%" alt=""/>-->
          <!--        </div>-->
        </CRow>
    </VueModal>
    <VueModal
      :title="geoDataBaseDetail.title"
      v-model="geoDataBaseDetailModal"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
    >
      <vue-element-loading
        :active="loading"
        text="دریافت جزئیات ژئودیتابیس"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <CRow>
        <img
          :src="getImage(geoDataBaseDetail.image)"
          class="geoDataBaseDetailImage"
        />
        <p class="geoDataBaseDetailDesc">
          {{ geoDataBaseDetail.description }}
        </p>
      </CRow>
    </VueModal>
  </main-body-section>
</template>

<script>
import {mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {ZoomControl} from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import {calculateBboxService} from "../../services/calculateBbox.service";
import polyGonStructure from "../../store/data/polygonFeature.json";
import {formatPolygonService} from "../../services/formatPolygon.service";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "jalali-moment";
import {apiUrlRoot} from "../../constants/config";
import PointStructure from "../../store/data/point.json";
import MainBodySection from "../../components/mainBodySection.vue";
import FarmsNameList from "../../components/map/FarmsNameList.vue";
import BasePanel from "../../components/map/BasePanel.vue";
import FarmInfo from "../../components/map/FarmInfo.vue";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "geoDataBase",
  components: {
    FarmInfo, BasePanel, FarmsNameList, MainBodySection,
    vSelect,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      map: null,
      loading: false,
      deleteLoading: false,
      loadingText: "",
      countryData: [],
      geoDataBaseDetailModal: false,
      deleteConfirm: false,
      selectFarmLoading: false,
      deleteMessage: "خطا در دریافت اطلاعات سطر",
      deleteId: 0,
      farmView: "",
      farmSource: [],
      polygonCenter: null,
      formatedPolygon: null,
      selectedFarmArea: null,
      farmSelected: "",
      farmsSource: [],
      cultiavtionType: ["دیم", "آبی", "دیم -آبیاری تکمیلی"],
      selectedFarm: "",
      geoDataBaseDetail: {
        title: "",
        image: "",
        description: "",
      },
      latSelected: null,
      lngSelected: null,
      formModel: {
        id: 0,
        title: null,
        type: null,
        description: null,
        image: null,
      },
      geoDataBaseTypeSource: [],
      modalShow: false,
      modalTitle: "",
      imageText: "تصویر مکان مورد نظر را انتخاب کنید",
      imagePreview: null,
    };
  },
  validations() {
    return {
      formModel: {
        title: { required },
        type: { required },
        description: { required },
        image: { required },
      },
    };
  },
  watch: {
    farmSelected: function () {
      this.selectFarm(this.farmSelected);
    },
  },
  methods: {
    ...mapActions("geoDataBase", [
      "GetAll",
      "GetGeoType",
      "GetById",
      "Insert",
      "Edit",
      "Delete",
    ]),
    ...mapActions("farm", ["GetsTextValue", "Get"]),

    onGeoDataBaseImageChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.formModel.image = files[0];
      this.imageText = this.formModel.image.name;
      this.imagePreview = URL.createObjectURL(this.formModel.image);
    },
    addPolygonLayer(polygon) {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      };
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine",
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });
    },
    loadFarmMap(cordinates) {
      polyGonStructure.features[0].geometry.coordinates = [cordinates];
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      var polygon = turf.polygon([cordinates]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.formatedPolygon = formatPolygonService.polygonreFormat(cordinates);
      let box = calculateBboxService.calculateBox(cordinates);
      this.addPolygonLayer(cordinates);
      this.map.fitBounds(box, { padding: 100 });
      this.map.on("load", () => {
      });
    },
    async selectFarm(item) {
      this.selectFarmLoading = true;
      var result = await this.Get({ id: item.value });
      this.kashtDatePersian = moment(result.cultivationsDate)
        .locale("fa")
        .format("YYYY-MM-D");
      result.kashtDatePersian = this.kashtDatePersian;
      this.selectedFarm = result;
      this.loadFarmMap(this.selectedFarm.cordinates);
      this.selectFarmLoading = false;
    },
    async fetchData() {
      var result = await this.GetAll();
      if (result) {
        result.forEach((value) => {
          new mapboxgl.Marker()
            .setPopup(
              new mapboxgl.Popup()
                .setHTML(`<div style="cursor: pointer;width: 220px; height: 130px;" >
              <img src="${apiUrlRoot}/GeoDataBase/${value.image}" style="width: 100%;height: 100px;float: right;clear: both;" />
              <div>
              <h5 style="float: right;clear: both;width: 100%;color: blueviolet;text-align: right;margin-top: 5px;background-color: #bbb;padding: 4px;">${value.title} (${value.type})</h5>
               <button type="button" style="width: 30px;height: 30px;background-color: #fff;color: #f80;border: 1px solid #bbb;border-radius: 100%;font-size: 16px;padding: 6px 6px;" onClick="showModal(${value.id})">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <button class="deleteCellBtn"  style="width: 30px;height: 30px;background-color: #fff;color: red;margin:0px 4px;border: 1px solid #bbb;border-radius: 100%;font-size: 16px;padding: 6px 6px;" onClick="deleteGeoDataBase(${value.id})">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>`)
            )
            .setLngLat([value.lat, value.lng])
            .addTo(this.map);
        });
      }
    },
    async GetAllFarms() {
      this.farmsNameListLoadingState = true;
      this.farmsSource = await this.GetsTextValue({
        farmerType: null,
        farmerId: null,
      });
      this.farmsNameListLoadingState = false;
    },
    async GetGeoDataBaseTypeSource(typeResult) {
      this.geoDataBaseTypeSource = [];
      var result = await this.GetGeoType();
      result.filter((item) => {
        let type = {
          label: item.title,
          value: item.id,
        };
        this.geoDataBaseTypeSource.push(type);
      });
      if (typeResult > 0) {
        this.formModel.type = this.geoDataBaseTypeSource.filter((item) => {
          return item.value == typeResult;
        })[0];
      }
    },
    getImage(image) {
      return `${apiUrlRoot}/GeoDataBase/${image}`;
    },
    async markViewInfo(id) {
      this.loading = true;
      this.geoDataBaseDetailModal = true;
      let result = await this.GetById({ id: id });
      if (result) {
        this.geoDataBaseDetail.title = result.title;
        this.geoDataBaseDetail.image = result.image;
        this.geoDataBaseDetail.description = result.description;
        this.loading = false;
      } else {
        this.$notify({
          title: "خطا رخ داده است",
          text: "خطا در دریافت جزئیات نقطه <br /><br />",
          type: "error",
        });
        this.loading = false;
        this.geoDataBaseDetailModal = false;
      }
    },
    async showModal(id) {
      if (id == 0) {
        this.formModel.id = 0;
        this.formModel.title = null;
        this.formModel.type = null;
        this.formModel.description = null;
        this.formModel.coordinationString = null;
        this.formModel.image = null;
        this.modalShow = true;
        this.modelTitle = "ثبت نقطه جدید.";
        this.GetGeoDataBaseTypeSource(0);
      } else {
        this.modelTitle = "ویرایش اطلاعات نقطه";
        this.loading = true;
        this.loadingText = "دریافت اطلاعات نقطه";
        let result = await this.GetById({ id: id });
        this.formModel.id = id;
        this.formModel.title = result.title;
        this.formModel.description = result.description;
        this.formModel.image = result.description;
        this.GetGeoDataBaseTypeSource(result.type);
        this.loading = false;
        this.modalShow = true;
      }
    },
    async deleteGeoDataBase(id) {
      this.$confirm({
        message: `نقطه مورد نظر حذف شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.deleteLoading = true;
            let result = await this.Delete({ id: id });
            this.deleteLoading = false;
            if (result) {
              if (result.succeeded) {
                await this.fetchData();
                this.$notify({
                  title: "پیام سیستم",
                  text: "سطر مورد نظر حذف شد <br /><br />",
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "خطا",
                  text: `${result.message} <br /><br />`,
                  type: "error",
                });
              }
            } else {
              this.$notify({
                title: "خطا",
                text: `خطا رخ داده است <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    async saveGeoDataBasePoint() {
      // Validate the form before saving
      this.v$.$validate();

      if (this.v$.$invalid) {
        this.$notify({
          title: "خطا",
          text: "لطفاً تمام فیلدهای مورد نیاز را پر کنید <br /><br />",
          type: "error",
        });
        return;
      }

      if (this.formModel.id == 0) {
        let formData = new FormData();
        formData.append("Title", this.formModel.title);
        formData.append("Type", this.formModel.type.value);
        formData.append("Description", this.formModel.description);

        PointStructure.geometry.coordinates = [
          this.latSelected,
          this.lngSelected,
        ];
        let newPointString = JSON.stringify(PointStructure, null, 0);
        formData.append("coordination", newPointString); //`${this.lngSelected},${this.latSelected}`);
        formData.append("Image", this.formModel.image);
        this.loading = true;
        this.loadingText = "در حال ثبت اطلاعات ...";
        let result = await this.Insert({ geoDataBase: formData });
        this.loading = false;
        this.modalShow = false;
        if (result) {
          if (result.succeeded) {
            await this.fetchData();
            this.$notify({
              title: "پیام سیستم",
              text: "نقطه مورد نظر ذخیره شد <br /><br />",
              type: "success",
            });
          } else {
            this.$notify({
              title: "خطا",
              text: `${result.message} <br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "خطا",
            text: `خطا رخ داده است <br /><br />`,
            type: "error",
          });
        }
      } else {
        let formData = new FormData();
        formData.append("Id", this.formModel.id);
        formData.append("Title", this.formModel.title);
        formData.append("Type", this.formModel.type.value);
        formData.append("Description", this.formModel.description);
        formData.append("Image", this.formModel.image);
        this.loading = true;
        this.loadingText = "در حال ویرایش اطلاعات ...";
        let result = await this.Edit({ geoDataBase: formData });
        this.loading = false;
        this.modalShow = false;
        if (result) {
          await this.fetchData();
          if (result.succeeded) {
            this.$notify({
              title: "پیام سیستم",
              text: "نقطه مورد نظر ویرایش شد <br /><br />",
              type: "success",
            });
          } else {
            this.$notify({
              title: "خطا",
              text: `${result.message} <br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "خطا",
            text: `خطا رخ داده است <br /><br />`,
            type: "error",
          });
        }
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() !== "loaded") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      //center: [59.55679711007316, 36.316138357670575], // starting position [lng, lat]
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {
    });
    this.map.on("draw.create", this.addPolygonLayer);
    this.map.on("click", (e) => {
      new mapboxgl.Marker().setLngLat(e.lngLat).addTo(this.map);
      this.latSelected = e.lngLat.lat;
      this.lngSelected = e.lngLat.lng;
      this.showModal(0);
    });
    this.fetchData();
    this.GetAllFarms();
  },
  created() {
    window.showModal = this.showModal;
    window.deleteGeoDataBase = this.deleteGeoDataBase;
    window.markViewInfo = this.markViewInfo;
  },
};
</script>

<style>
/* Add your styles here */
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
}

.tension-page .ads-wrapper img {
  width: 22rem !important;
}
</style>
<style scoped>

.geoDataBaseDetailImage {
  width: 100%;
  height: 150px;
  float: right;
  clear: both;
}

.geoDataBaseDetailDesc {
  width: 100%;
  padding: 5px;
  color: #f80;
  margin-top: 5px;
}

.inputLabel {
  font-size: 13px;
  margin-top: 6px;
}

.imageSelectedPreview {
  width: 47%;
  min-height: 200px;
  margin: 7px;
  float: right;
}

</style>
